export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}

export const onRouteUpdate = ({ location }) => {
  const isTakeaway = location.pathname.indexOf("takeaway") !== -1
  if (isTakeaway && typeof fbq === "function") {
    fbq("track", "ViewContent")
  }
}
