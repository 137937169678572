// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-template-js": () => import("/opt/build/repo/src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-done-js": () => import("/opt/build/repo/src/pages/checkout/done.js" /* webpackChunkName: "component---src-pages-checkout-done-js" */),
  "component---src-pages-checkout-index-js": () => import("/opt/build/repo/src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-en-js": () => import("/opt/build/repo/src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-completed-js": () => import("/opt/build/repo/src/pages/order/completed.js" /* webpackChunkName: "component---src-pages-order-completed-js" */),
  "component---src-pages-takeaway-js": () => import("/opt/build/repo/src/pages/takeaway.js" /* webpackChunkName: "component---src-pages-takeaway-js" */)
}

